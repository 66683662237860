html {
  font-size: 1rem;
}

/************ Custom Colors and Misc ******************/
:root {
  --tiffany-blue: #16adef;
  --accent: #16adef;
}

.btn-primary {
  background-color: var(--tiffany-blue) !important;
  border-color: var(--tiffany-blue) !important;
}

.btn-outline-light {
  border-radius: 20px !important;
}
.btn-outline-primary {
  color: var(--tiffany-blue) !important;
  border-color: var(--tiffany-blue) !important;
  border-radius: 20px !important;
}
.btn-outline-primary:hover {
  color: white !important;
  background-color: var(--tiffany-blue) !important;
}

.bg-navBG {
  background-color: white;
  box-shadow: 5px 0px 5px black;
}
.bg-tiffany {
  background-color: var(--tiffany-blue);
  box-shadow: 5px 0px 5px black;
}

.diagonal-design {
  background: linear-gradient(-5deg, white 40%,whitesmoke 40%);
}

/*************** APP ***************************/

.App {
  height: 100%;
}

.Main {
  height: 100%;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}
.Main::after{
  content: "";
  display: block;
  position: absolute;
  z-index: -1;   
  width: 100%;
  height: 90%;
  top: 0;
  background-image: url("public_assets/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/********************** Central Column **************************************/

.central-column{
  padding: 4% 5%
}

/********************** Body Section **************************************/

.main_body-section{
  min-height: 55%;
}

.main_body-section__one{
  background-color: white;
}

.main_body-section__two{
  background-color: whitesmoke;
}

/********************** NAVBAR **************************************/
.nav-white .nav-link{
  font-size: 18px;
  color: white;
}
.nav-blue .nav-link{
  font-size: 18px;
  color: var(--tiffany-blue);
}
.nav-link.to-about {
  display: block;
  position: relative;
  top: -2.75vh;
  visibility: hidden;
}
.custom-toggler-blue .navbar-toggler {
    border-color: rgb(22,173,239);
}
.custom-toggler-blue .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(22,173,239, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.custom-toggler-white .navbar-toggler {
    border-color: rgb(255,255,255);
}
.custom-toggler-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

/**************************** COMPANY PHRASE *****************************/
.companyPhrase {
  position: relative;
  top: 25%;
  color: white;
  text-align: center;
}
.companyPhrase h1 {
  font-size: 70px;
  font-weight: bold;
}
.companyPhrase h3 {
  font-size: 50px;
}

/********* MODALS ******************************/
.ElementaryModal {
  max-width: 85%!important;
}
.JhsModal {
  max-width: 85%!important;
}
.HsModal {
  max-width: 85%!important;
}
.ConsultingModal {
  max-width: 85%!important;
}
.CovidCrisisModal {
  width: 30em!important;
}
.CovidCrisisModal .modal-header {
  background-color: lightgray;
}
.CovidCrisisModal .modal-body {
  padding-bottom: 0;
}
.CovidCrisisModal .modal-footer {
  padding-top: 0;
  border-top: none;
}


/******** ABOUT ********/
.to-about {
  display: block;
  position: relative;
  top: -125px;
  visibility: hidden;
}
.to-about-with-menu {
  display: block;
  position: relative;
  top: -375px;
  visibility: hidden;
}

/****** CAROUSEL *******/
.school-carousel img{
  filter: grayscale(100%);
}
.school-carousel img:hover {
  filter: none;
  transition: ease-in-out .3s;
}
.school-carousel img a:active, a:focus {
  outline: 0;
  border: none;
}

/************ WHY MERICA *************/
.why-merica-container img {
  width: 150px;
}

/************ Theme Background ****************/
.footer_main{
  background-color: var(--tiffany-blue);
}

.footer_accent{
  background-color: var(--accent);
  height: 3rem;
}