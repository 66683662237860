@font-face {
  font-family: 'Proxima';
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  src: url('./public_assets/fonts/proximanova-regular.otf') format('opentype');
}

html, body, #root{
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Proxima' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
